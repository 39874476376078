// zuid of the customer
export const ZUID = 'zuid';
export const COUNTRY = 'country';
export const LANGUAGE = 'language';

// the value of the key from persistent storage
export const STORE_KEY = 'store.rcs';
export const SESSION_ID = 'sessionId';
export const SGS = 'sgs';
export const ANONYMOUS_ID = 'anonymousId';
export const SEGMENT = 'segment';
export const LAST_VIEWED_SKUS = 'lastViewedSku';
export const EVALUATION_TOKEN = '@';
export const START_DEFAULT_TOKEN = '{';
export const END_DEFAULT_TOKEN = '}';
export const QUERY_PARAM_SEPARATOR = '&';

// /[ - matches a '[' character
// [^] - matches any character that is not in the set
// \] - matches a ']' character
// [ - matches a '[' character
// * - match 0 or more of the preceding token
// ] - matches a ']' character
export const SLUG_REGEX_PATTERN = /\[[^\][]*]/gi;

export const ERROR_PREFIX = 'ALITA - URL Parser:';
export const ENVIRONMENT_LIVE = 'live';
