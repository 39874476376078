import { ZDTCustomer } from '@zalora/doraemon-ts';
import { SEGMENTS_LIST } from 'constants/segments';
import { getEnvConfiguration } from 'utils/env-configuration';
import { captureError } from 'utils/raven';
import {
  SLUG_REGEX_PATTERN,
  EVALUATION_TOKEN,
  ZUID,
  COUNTRY,
  LANGUAGE,
  STORE_KEY,
  SESSION_ID,
  SEGMENT,
  SGS,
  LAST_VIEWED_SKUS,
  START_DEFAULT_TOKEN,
  END_DEFAULT_TOKEN,
  QUERY_PARAM_SEPARATOR,
  ANONYMOUS_ID,
} from './constants';

export interface SlugParserData {
  [ZUID]?: string;
  [COUNTRY]?: string;
  [LANGUAGE]?: string;
  [STORE_KEY]?: string;
  [SESSION_ID]?: string;
  [SEGMENT]?: string;
  [LAST_VIEWED_SKUS]?: string[];
  [ANONYMOUS_ID]?: string;
  [key: string]: string | string[] | undefined;
}

/**
 * External URL Parser: helps to parse the data slugs in the url.
 * Ref ticket: https://zalora.atlassian.net/browse/SHOP-27361
 *
 * eg: [https://{recom_domain}]?apiKey=212ef076ae59b7c9&apiClientKey=0fb6a09fdc84bbbf&excludeHtml=true&ssl=true[sgs:&sgs=@][sessionId:&sessionId=@][zuid:&userId=@][store.rcs:&rcs=@]
 *
 * [sgs:&sgs=@]
 * => anything inside the '[]' is said to be a slug
 * => text that is left to ':' is the slug's type
 * => text that is right to ':' slug's evaluation
 *
 * to understand about the evaluation, refer the fn evaluateSlugType
 */
export const slugParser = (stringWithSlugs: string, data: SlugParserData): string => {
  if (!stringWithSlugs) {
    return '';
  }

  const slugs = stringWithSlugs.match(SLUG_REGEX_PATTERN);

  if (!slugs) {
    return stringWithSlugs;
  }

  const evaluatedUrl = slugs.reduce(
    (prev, slug) => prev.replace(slug, evaluateSlugType(slug, data)),
    stringWithSlugs,
  );

  return evaluatedUrl;
};

export const evaluateSlugType = (slug: string, data: SlugParserData): string => {
  try {
    // the evaluation token is "@". If the string eval section of the slug is missing "@",
    // it is a syntax error and the slug will evaluate to empty string.
    if (slug.indexOf(EVALUATION_TOKEN) < 0) {
      return '';
    }

    // for eg, if given this slug string [zuid:&user=@]
    // slugType is "zuid"
    // slugEvaluation is "&user=@" and the value after evaluation is "&user=123456789"
    const slugs = slug.split(':');
    const rawSlugType = slugs[0];

    if (!rawSlugType || slugs.length < 2) {
      return '';
    }

    const rawSlugEvaluation = slugs.slice(1).join(':');

    // eliminate the '[' from rawSlugType => zuid
    const slugType = rawSlugType.substring(1);
    // eliminate the ']' from rawSlugEvaluation => &user=@
    const cleanedSlugEvaluation = rawSlugEvaluation.substring(0, rawSlugEvaluation.length - 1);
    const [slugEvaluation, numberOfRepeat, fallback] = cleanedSlugEvaluation.split(':');
    const slugValue = getSlugValue(slugType, data);

    // if slug value is empty, remove the slug expression from the url
    if (!slugValue) {
      return '';
    }

    if (!numberOfRepeat) {
      // after evaluation => &user=123456789
      return slugEvaluation.replace(EVALUATION_TOKEN, slugValue as string);
    }

    const defaultValues = fallback
      .replace(START_DEFAULT_TOKEN, '')
      .replace(END_DEFAULT_TOKEN, '')
      .split(',');

    // slugEvaluation = "&config_sku_or_slug=@"
    const queryParam = slugEvaluation.split('=')[0].replace(QUERY_PARAM_SEPARATOR, '');

    // if there's no last viewed skus, we'll get all fallback
    // Note: the last viewed skus are in the end of the array
    const values =
      Array.isArray(slugValue) && slugValue.length
        ? slugValue.slice(-numberOfRepeat)
        : defaultValues;

    const res = values.map((_, index) => `${queryParam}=${(values as string[])[index]}`).join('&');

    return `${QUERY_PARAM_SEPARATOR}${res}`;
  } catch (e) {
    captureError('Failed to evaluate slug type', { error: e, tag: 'handler' });

    return '';
  }
};

export const getSlugValue = (slugType: string, data: SlugParserData): string | string[] => {
  const dataSlugType = data[slugType] || '';

  if (!dataSlugType) {
    return '';
  }

  return dataSlugType;
};

export const prepareSlugData = ({
  activeSegment,
  lang,
  userId,
  rrSessionId,
  storeKey,
  lastViewedSkus,
  anonymousId,
}: {
  activeSegment?: string;
  lang?: string;
  userId?: ZDTCustomer.Customer['Id'];
  rrSessionId?: string;
  storeKey?: string;
  lastViewedSkus?: Nullable<string[]>;
  anonymousId?: string;
}): SlugParserData => {
  const countryCode = getEnvConfiguration('CC')?.toLowerCase() || '';

  //TODO: Cleanup fields that are not needed (related to RR)
  const data = {
    [ZUID]: userId || '',
    [COUNTRY]: countryCode,
    [LANGUAGE]: lang,
    [STORE_KEY]: storeKey || '',
    [SESSION_ID]: rrSessionId || '',
    [ANONYMOUS_ID]: anonymousId,
    ...(lastViewedSkus
      ? {
          [LAST_VIEWED_SKUS]: lastViewedSkus,
        }
      : {}),
  };
  const segments = SEGMENTS_LIST[countryCode as keyof typeof SEGMENTS_LIST] || [];

  if (activeSegment && segments.includes(activeSegment)) {
    return {
      ...data,
      [SEGMENT]: activeSegment,
      [SGS]: `${activeSegment}:${activeSegment}`,
    };
  }

  return data;
};
