import { HorizontalScale } from '@zalora/post-types/lib/Banner/types';

export const AVAILABLE = 'available';
export const NOT_AVAILABLE = 'not_available';
export const LOADING_DELAY = 500;

// this count is used for lazy loading the posts
// modifying this will make significant difference
// in rendering the posts on segment page
export const DEFAULT_POSTS_COUNT = 10;
export const FULL_WIDTH_SKINNY = 'Full width skinny';
export const CUSTOM_BANNER_OFFSETS = [
  HorizontalScale.Scale,
  HorizontalScale.Scale2x,
  HorizontalScale.DesktopScale,
];
export const SCROLL_SCALE_MEDIA_MAPPING_MOBILE = {
  1: HorizontalScale.NoScale,
  2: HorizontalScale.Scale,
};

// this is the threshold for minimum number of keys
// that should be available for external source data.
// if key < 2 then there is no data/has error message.
// if key === 2 then the data is available.
// modifying this will affect the UI flow.
export const MIN_KEYS_EXTERNAL_SOURCE_DATA = 2;

// The percent of the MediaItem that needs to be visible
// in the viewport for it to be considered an "impression".
export const DEFAULT_VIEWPORT_VISIBILITY_THRESHOLD = 0.1;
export const DEFAULT_VIEWPORT_VISIBILITY_ROOT_MARGIN = '300px';
export const URL_EMPTY_ERROR_MESSAGE =
  'URL is empty, Please provide a valid one to initiate the request';

// Post variant type (FLexi Grid/Category Grid)
export const enum POST_VARIANT_TYPE {
  C1C5 = 'C1-C5',
  CU1CU4 = 'Cu1-Cu4',
  NU1NU2 = 'Nu1-Nu2',
}

export const CU_MEDIA_LENGTH = 4;
export const NU_MEDIA_LENGTH = 2;
export const C_MEDIA_LENGTH = 5;

export const STATIC_PLACEMENT = 'static';

export const enum ANIMATION_POST_TYPE_CONFIG {
  CUSTOM_STYLE_CLASS_NAME = 'custom-style',
  ANIMATION_WRAPPER_CLASS = 'animation-wrapper',
  ANIMATED_POST_CLASS = 'animated-post',
  ANIMATION_OBSERVER_THRESHOLD = 0.1,
}

export const enum PRE_WRAPPER_CLASS {
  FULL_BLEED_PRE_WRAPPER_CLASS = 'post-type-pre-wrapper__full-bleed',
  HALF_BLEED_PRE_WRAPPER_CLASS = 'post-type-pre-wrapper',
}

// Animations
export enum ANIMATION {
  SLIDE_IN_FROM_LEFT = 'Slide in from left',
  SLIDE_IN_FROM_RIGHT = 'Slide in from right',
  SCROLL_IN_WITH_LAG = 'Scroll in with a swift lag',
  POP_IN = 'Pop in',
}

export const ANIMATION_TYPE = {
  [ANIMATION.SLIDE_IN_FROM_LEFT]: 'slide-left',
  [ANIMATION.SLIDE_IN_FROM_RIGHT]: 'slide-right',
  [ANIMATION.SCROLL_IN_WITH_LAG]: 'scroll-lag',
  [ANIMATION.POP_IN]: 'pop-in',
};

export const enum BLEED_TYPE {
  HALF = 'Half',
  FULL = 'Full',
}

export const FLEXI_GRID_COLUMNS = 2;
