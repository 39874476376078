import { MediaType, MediaElement, PriceType, BleedType } from '@zalora/post-types/lib/common/types';
import { ExtendedRRData, RRData } from 'api/externalAPI';
import { CONTENT_TYPE } from 'constants/contentful';
import { SCROLL_SCALE_MEDIA_MAPPING_MOBILE } from 'constants/post-types';
import { RawBanner } from './BannerTypes';
import { RawBubbles } from './BubblesTypes';
import { RawCategoryGrid } from './CategoryGridTypes';
import { RawFlexiGrid } from './FlexiGridTypes';
import { RawGrid } from './GridTypes';
import { RawHTML } from './HTMLTypes';
import { RawRecommendation } from './RecommendationTypes';
import { RawTextBanner } from './TextBannerTypes';

// -- MISC --

export const enum FetchStatus {
  Loading = 'loading',
  Loaded = 'loaded',
  Failed = 'failed',
}

export type FetchFromExternalSource = (externalSources: RefinedDataSource[]) => void;

export interface Options {
  headers: HeadersInit;
}

export interface PostStyleParams {
  fontColor?: string;
  backgroundImageUrl?: string;
  backgroundColor?: string;
}

export interface PostStyles {
  background?: string;
  backgroundSize?: string;
  backgroundRepeat?: string;
  color?: string;
  marginBottom?: string;
}

export type ScrollScaleMappingMobile = keyof typeof SCROLL_SCALE_MEDIA_MAPPING_MOBILE;

// -- Props --

export interface RawMediaItem {
  entryId: string;
  contentType?: CONTENT_TYPE.Asset;
  description?: string;
  title?: string;
  file: {
    url: string;
    details?: {
      size: number | string;
      image?: {
        width: number | string;
        height: number | string;
      };
    };
    fileName: string;
    contentType?: string;
  };
}

export interface RawInternalMedia {
  entryId: string;
  contentType: CONTENT_TYPE.INTERNAL_MEDIA;
  audience: string;
  countdownTimerText?: string;
  countdownTimerStartTime?: string;
  countdownTimerEndTime?: string;
  description?: string;
  injectSegment?: boolean;
  internalName: string;
  internalPromotionalName?: string;
  mediaDesktopWeb?: RawMediaItem;
  media?: RawMediaItem;
  variantMediaDesktopWeb?: RawMediaItem;
  variantMedia?: RawMediaItem;
  navLink?: string;
  variantNavigationLink?: string;
  title?: string;
  trackingUrl?: string;
  type: MediaType;
  mediaOverlayTitle?: string;
  mediaOverlayDescription?: string;
  width?: number | string;
  isHide?: boolean;
  enableSegmentAudienceFilter?: boolean;
  segmentAudience?: string;
  audienceBucket?: AudienceBucket[];
  mWebAbTestName?: string;
  dWebAbTestName?: string;
}

export interface RawExternalMedia {
  entryId: string;
  contentType: CONTENT_TYPE.EXTERNAL_MEDIA;
  audience: string;
  countdownTimerText?: string;
  countdownTimerStartTime?: string;
  countdownTimerEndTime?: string;
  description?: string;
  height?: number | string;
  imageUrl?: string;
  injectSegment?: boolean;
  internalName: string;
  internalPromotionalName?: string;
  navLink?: string;
  sourceConfig?: SourceConfig;
  title?: string;
  trackingUrl?: string;
  type: MediaType;
  width?: number | string;
  mediaOverlayTitle?: string;
  mediaOverlayDescription?: string;
  mediaDesktopWeb?: RawMediaItem;
  media?: RawMediaItem;
  isFetched?: boolean;
  isHide?: boolean;
  enableSegmentAudienceFilter?: boolean;
  segmentAudience?: string;
  audienceBucket?: AudienceBucket[];
}

export interface SourceConfig {
  entryId: string;
  contentType: CONTENT_TYPE.sourceConfig;
  internalName: string;
  data: SourceConfigModel;
  source: number;
}

export type RawMedia = RawInternalMedia | RawExternalMedia;

export interface MediaEntryContentType {
  entryId: string;
  contentType: string;
  imageUrl: string;
  audience: string;
  ratio?: number;
}

export interface RawCommonPostType {
  entryId?: string;
  abtestFlagName?: string;
  audience?: string;
  contentType: CONTENT_TYPE;
  internalName: string;
  title: string;
  description?: string;
  countdownTimerText?: string;
  countdownTimerStart?: number | undefined;
  countdownTimerEnd?: number;
  bleedType?: BleedType;
  backgroundColor?: string;
  backgroundImage?: BackgroundImage;
  fontColor?: string;
  animation?: string;
  ctaNavigationLink?: string;
  ctaLabel?: string;
  media?: MediaEntryContentType[];
  postShelfRank: number;
  isPersonalizePost?: boolean;
  enableSegmentAudienceFilter?: boolean;
  segmentAudience?: string;
  audienceBucket?: AudienceBucket[];
}

export interface PreviewParam {
  previewTime?: string;
  isPreviewMode?: boolean;
  shouldDisableMediaTimers?: boolean;
  itemsAvailable?: {
    [s: string]: boolean;
  };
}

export type RawPostType =
  | RawBubbles
  | RawGrid
  | RawTextBanner
  | RawBanner
  | RawFlexiGrid
  | RawCategoryGrid
  | RawRecommendation
  | RawHTML;

export type RawMediaPostType = RawBanner | RawBubbles | RawGrid | RawFlexiGrid | RawCategoryGrid;

export interface PostTypeProps {
  activeKey: string;
  fetchStatus?: FetchStatus;
  posts?: RawPostType[];
  dataSources?: FormattedDataSources;
  backgroundColor?: string;
  postsSpacing?: string;
  renderedCallback: () => void;
  enablePersonalizeSegmentFlag?: boolean;
  listAbTestFlag?: string[];
}

export interface FormattedDataSources {
  [key: number]: DataSource;
}

export interface RawPromotion {
  promotion_id: string;
  promotion_creative: string;
  promotion_name?: string;
  promotion_position: string | null;
  promotion_vendor_source: string;
}

// -- Data --

export interface SourceConfigModel {
  [key: string]: string;
}

export interface SourceConfigData<T = unknown> {
  entryId?: string;
  items?: T[];
  language?: string;
  dynTitle?: string;
  maxItems?: number;
  description?: string;
  title?: string;
  trackingUrl?: string;
  desktopImageUrl?: string;
  url?: string;
  width?: number;
  height?: number;
  internalPromotionName?: string;
  placement?: string;
}

export interface SegmentData {
  fetchStatus?: FetchStatus;
  error?: string;
  posts?: RawPostType[];
  seoText?: string;
  dataSources?: { [key: number | string]: DataSource };
  backgroundColor?: string;
  mediaList?: {
    [id: string]: MediaWithPlacement | RawExternalMedia;
  };
  fetchedDataSources?: {
    [idSource: string | number]: ExternalData;
  };
  pageType?: string;
  postsSpacing?: string;
  title?: string;
  name?: string;
  listAbTestFlag?: string[];
}

export interface MediaWithPlacement extends MediaElement {
  placement?: string;
  position?: number;
  xyPosition?: string;
  contentType?: string;
  entryId?: string;
  isFetched?: boolean;
  postType?: string;
  isHide?: boolean;
  postShelfRank?: number;
  postTitle?: string;
  postInternalName?: string;
  enableSegmentAudienceFilter?: boolean;
  segmentAudience?: string;
  audienceBucket?: AudienceBucket[];
  priority?: 'high' | 'low' | 'auto';
  mediaData?: ImageInfo;
  mediaVariant?: ImageInfo;
  abTestName?: string;
  variantNavigationLink?: string;
}

export enum AudienceBucket {
  Anonymous = 'Anonymous',
  NewlySignedUp = 'Newly Signed Up',
}

export type ExternalSourceData = Record<string, ExtendedRRData>;

export interface DataSource {
  entryId: string;
  id: number;
  name: string;
  headers?: HeadersInit;
  store?: Store[];
  url: string;
}

export enum URL_TYPE {
  DJ = 'datajet',
  SPONSORED = 'Sponsored (GFG)',
}

export type RefinedDataSource = Omit<DataSource, 'id' | 'name' | 'store'>;

interface Store {
  duration: number;
  from: string;
  key: string;
  mapping: string;
}

export interface ExternalData {
  isSuccess: boolean;
  response?: RRData;
  idSource: number;
  error?: string;
  fetched?: boolean;
  urlType?: string;
}

export interface ProcessedExternalData {
  isSuccess: boolean;
  error?: string;
  externalData?: RRData;
}

export interface RawProductPrice {
  priceFormatted?: string;
  specialPriceFormatted?: string;
  previousPrice?: number;
  currentPrice?: number;
}

export interface ProductPrice {
  current?: PriceType;
  previous?: PriceType;
}

export interface PromotionalEventData extends HompageSegmentTrackingData {
  post_type: string | null;
  promotions: RawPromotion[];
}

export interface HompageSegmentTrackingData {
  source_segment: string | null;
  source_page_type: string;
  list_id: string | null;
}

export type AnimationType =
  | 'Slide in from left'
  | 'Slide in from right'
  | 'Scroll in with a swift lag'
  | 'Pop in'
  | '';

export interface CommonPostTypeProps {
  dataPosition: number;
  styles: PostStyles;
  className?: string;
  animationClassName?: string;
  dataSources?: DataSource[];
  dataSourcesData?: ExternalSourceData;
}

export interface RefinedPostTypeProps {
  dataPosition?: number;
  className?: string;
  activeKey: string;
  styles?: PostStyles;
  renderedCallback?: () => void;
  recFeedRank?: number;
}

export interface BackgroundImage {
  file?: {
    url?: string;
  };
}

export interface ImageInfo {
  mediaUrl: string;
  width: number;
  height: number;
  mediaTitle: string;
}

export interface UserGroupData {
  isUserAudienceDataExits: boolean;
  userAudienceBucketType?: AudienceBucket;
}
