import { ZDTCustomer } from '@zalora/doraemon-ts';
import { camelCase } from 'services/contentful/helper';
import { LotusRecommendationProduct } from 'types/LDT';
import {
  DataSource,
  FetchStatus,
  URL_TYPE,
  ExternalData,
  SourceConfig,
} from 'types/post-types/PostTypes';
import { ExternalSourceRecommendation } from 'types/post-types/RecommendationTypes';
import { ProductElementPostType } from 'types/trackingPayload/HomePageTrackingPayload';
import { getDeviceId } from 'utils/device-id';
import { getEnvConfiguration } from 'utils/env-configuration';
import isServer from 'utils/is-server';
import { getLanguage } from 'utils/locale';
import { formatMoney } from 'utils/money';
import { getRecentViewedSkus } from 'utils/pdv/last-viewed-skus';
import { getExternalSourceData } from 'utils/post-type/helper';
import { captureError } from 'utils/raven';
import { slugParser, prepareSlugData, SlugParserData } from 'utils/url-parser';
import { URL_EMPTY_ERROR_MESSAGE } from './../constants/post-types';

export interface Options {
  headers: HeadersInit;
  timeout?: number;
  method?: string;
  baseURL?: string;
  credentials?: RequestCredentials;
}

export interface ExtendedRRData extends RRData {
  fetchStatus: FetchStatus;
}

export interface RRData {
  placements?: PersonalizeRRPlacement[] | RecFeedRRPlacement[];
  message?: string;
  status?: string;
}

export interface RecFeedRRPlacement {
  strategyMessage: string;
  recommendedProducts: ProductElementPostType[];
  placement: string;
}

export interface PersonalizeRRPlacement {
  creatives?: Record<string, string>[];
  placement?: string;
}

export const fetchExternalData = async (
  url: string,
  options: Options,
  idSource: number,
): Promise<ExternalData> => {
  try {
    if (!url) {
      throw URL_EMPTY_ERROR_MESSAGE;
    }

    const data = await fetch(correctUrl(url), options).then((res) => res.json());

    return { isSuccess: true, response: data, idSource, fetched: true, urlType: urlType(url) };
  } catch (error) {
    return {
      isSuccess: false,
      error: 'Can not fetch data of datasource',
      idSource,
      fetched: true,
      urlType: urlType(url),
    };
  }
};

export const isDatajetUrl = (string: string) => {
  return string.includes('/datajet');
};

// clear after release contentful support DOR API
export const isSponsoredUrl = (string: string) => {
  return string.includes('_c/v1/products/sponsored');
};

export const isDorSponsoredApi = (string: string) => {
  return string.includes('/v1/products/sponsored') || string.includes('/v1/recommendation/datajet');
};

export const fetchDataSourceAPI = async ({
  sourceConfigOfPost,
  activeSegment,
  locale,
  zuid,
}: {
  sourceConfigOfPost: DataSource[];
  activeSegment: string;
  locale?: string;
  zuid?: ZDTCustomer.Customer['Zuid'];
}) => {
  if (!sourceConfigOfPost.length) {
    return [];
  }

  const data = await Promise.all(
    sourceConfigOfPost.map((dataSource: DataSource) =>
      fetchDataFromExternalSource({
        activeSegment,
        dataSource,
        locale,
        zuid,
      }),
    ),
  );

  return data.reduce((filteredData: ExternalData[], item) => {
    if (item) {
      filteredData.push(item);
    }

    return filteredData;
  }, []);
};

export const isUrl = (string: string) => {
  try {
    return Boolean(new URL(string));
  } catch (e) {
    return false;
  }
};

export const urlType = (url: string) => {
  if (isDatajetUrl(url)) {
    return URL_TYPE.DJ;
  }

  if (isSponsoredUrl(url)) {
    return URL_TYPE.SPONSORED;
  }

  return '';
};

export const correctUrl = (url: string) => {
  if (isUrl(url) || url?.[0] === '/') {
    return url;
  }

  return `/${url}`;
};

export const replaceSlugsInHeaders = (headers: HeadersInit, slugParserData: SlugParserData) => {
  if (headers instanceof Headers) {
    return headers;
  }

  if (Array.isArray(headers)) {
    const updatedHeaders = headers.map(([key, value]) => {
      return [key, slugParser(value, slugParserData)];
    });

    return Object.fromEntries(updatedHeaders) as Record<string, string>;
  }

  const updatedHeaders = { ...headers };

  for (const header in updatedHeaders) {
    if (typeof updatedHeaders[header] === 'string') {
      updatedHeaders[header] = slugParser(updatedHeaders[header], slugParserData);
    }
  }

  return updatedHeaders;
};

export const fetchDataFromExternalSource = async ({
  activeSegment,
  dataSource,
  locale,
  zuid,
  shouldIgnoreHeaders,
}: {
  activeSegment?: string;
  dataSource: DataSource;
  locale: string | undefined;
  zuid?: ZDTCustomer.Customer['Zuid'];
  shouldIgnoreHeaders?: boolean;
}) => {
  try {
    const lastViewedSkus = dataSource.url.includes('datajet') ? getRecentViewedSkus() : null;

    const slugData = prepareSlugData({
      activeSegment,
      lang: getLanguage(locale),
      userId: zuid || '',
      anonymousId: getDeviceId() || '',
      lastViewedSkus,
    });

    const parsedUrl = slugParser(dataSource.url, slugData);

    const headers = shouldIgnoreHeaders
      ? {}
      : replaceSlugsInHeaders(dataSource.headers || {}, slugData);

    return fetchExternalData(
      parsedUrl,
      {
        headers: {
          ...headers,
          'content-language': locale || '',
          Accept: 'application/json',
        },
        timeout: 30000,
        credentials: isDorSponsoredApi(parsedUrl) ? 'include' : undefined,
        method: 'GET',
        baseURL: isServer()
          ? getEnvConfiguration('DOR_API_HOST_INTERNAL')
          : getEnvConfiguration('DOR_API_HOST_EXTERNAL'),
      },
      dataSource.id,
    );
  } catch (error) {
    captureError(`Error fetching data from Contentful external source: ${dataSource.id}`, {
      error,
      tag: 'get-request',
    });

    return undefined;
  }
};

export const convertExternalSourceDataToLotusRecommendationProducts = async ({
  data,
  sourceConfig,
}: {
  data: ExternalData | undefined;
  sourceConfig: SourceConfig;
}) => {
  if (!data || !data.response) {
    return [];
  }

  const maxItems = parseInt(sourceConfig.data.max_items) || 30;
  const sourceData = { ...sourceConfig.data, max_items: `${maxItems}` };
  const externalSourceData = getExternalSourceData<{ items: unknown[] }>(sourceData, data.response);
  const jsl = camelCase(sourceData);

  return externalSourceData.items.slice(0, maxItems).map((item) => {
    const externalItem = getExternalSourceData<ExternalSourceRecommendation>(jsl, item);

    const price = externalItem.previousPrice
      ? formatMoney(externalItem.previousPrice, 'number')
      : NaN;

    const specialPrice = externalItem.currentPrice
      ? formatMoney(externalItem.currentPrice, 'number')
      : NaN;

    const product: LotusRecommendationProduct = {
      Brand: externalItem.brandName || '',
      ConfigSku: externalItem.configSku || '',
      MainImageUrl: externalItem.images || '',
      Name: externalItem.productName || '',
      Price: `${externalItem.previousPrice}` || '',
      PriceInDecimal: price,
      SpecialPrice: `${externalItem.currentPrice}` || '',
      SpecialPriceInDecimal: specialPrice,
      Url: externalItem.formattedUrl || '',
    };

    return product;
  });
};
