/* eslint-disable max-lines */
import { ZDTCustomer, ZDTTwilioSegment } from '@zalora/doraemon-ts';
import { BannerVariations, HorizontalScale } from '@zalora/post-types/lib/Banner/types';
import { BleedType, LoadingState, MediaType } from '@zalora/post-types/lib/common/types';
import { AUDIENCE_DESKTOP, AUDIENCE_MOBILE_WEB, CONTENT_TYPE } from 'constants/contentful';
import {
  AVAILABLE,
  CUSTOM_BANNER_OFFSETS,
  CU_MEDIA_LENGTH,
  C_MEDIA_LENGTH,
  NU_MEDIA_LENGTH,
  POST_VARIANT_TYPE,
  PRE_WRAPPER_CLASS,
  SCROLL_SCALE_MEDIA_MAPPING_MOBILE,
  STATIC_PLACEMENT,
} from 'constants/post-types';
import { isTrueValue, toMilliseconds, toNumber } from 'services/contentful/helper';
import { RawFlexiGrid } from 'types/post-types/FlexiGridTypes';
import { RawGrid } from 'types/post-types/GridTypes';
import {
  PostStyleParams,
  PostStyles,
  MediaWithPlacement,
  RawExternalMedia,
  RawInternalMedia,
  SourceConfigData,
  SourceConfigModel,
  RawProductPrice,
  ProductPrice,
  FetchStatus,
  MediaEntryContentType,
  RawMediaPostType,
  RawMediaItem,
  ScrollScaleMappingMobile,
  AudienceBucket,
  UserGroupData,
  RawMedia,
} from 'types/post-types/PostTypes';
import { formatMoney } from 'utils/money';
import { isMobileScreenSize } from 'utils/screen-size';
import { isObject } from 'utils/validation';
import { select } from './jslParser';

export const getClassNameByBleed = (bleedType?: BleedType): string => {
  return bleedType === BleedType.Full
    ? PRE_WRAPPER_CLASS.FULL_BLEED_PRE_WRAPPER_CLASS
    : PRE_WRAPPER_CLASS.HALF_BLEED_PRE_WRAPPER_CLASS;
};

export const getPostStyles = ({
  fontColor,
  backgroundImageUrl,
  backgroundColor,
}: PostStyleParams): PostStyles => {
  const styles: PostStyles = {};

  if (fontColor) {
    styles.color = fontColor;
  }

  const isMediaUrlExits = !!backgroundImageUrl;

  if (isMediaUrlExits) {
    styles.background = `url(${backgroundImageUrl})`;
    styles.backgroundSize = 'cover';
    styles.backgroundRepeat = 'no-repeat';
  } else if (backgroundColor) {
    styles.background = backgroundColor;
  }

  return styles;
};

export const getExternalSourceData = <T>(
  data: SourceConfigModel,
  externalSourceData?: unknown,
): T => {
  // The source config is the only fail proof way to extract the data from
  // the RR response. This is a hack to get the current placement name of
  // the media/image by creating the respective prefix and attaching the
  // suffix "placement" to the prefix and extract the current placement name.
  // Note: No other way to get the placement name. The prefix value is usually
  // ".placements[0]."
  const placeholderValue = data.internalPromotionName || data.items;
  const prefix = placeholderValue?.substring?.(0, 15) || '';
  const fields = Object.entries({
    ...data,
    placement: prefix ? `${prefix}placement` : '',
  }).reduce(
    (prev, [fieldName, fieldValue]) => {
      if (isJSLPath(fieldValue)) {
        // selects the value of the path from an object where we are
        // not sure if the value that is nested deep is available or not.
        const evaluatedValue = select(fieldValue as string, externalSourceData);

        return { ...prev, [fieldName]: evaluatedValue };
      }

      // return the null if its not a JSL path
      return { ...prev, [fieldName]: null };
    },
    {
      navLink: '',
      type: MediaType.Image,
      mediaUrl: '',
      isFetched: true,
      contentType: CONTENT_TYPE.EXTERNAL_MEDIA,
    },
  );

  return fields as T;
};

/**
 * checks if inpur param is a valid path
 * (should start with 'dot')
 */
export const isJSLPath = (path: string): boolean => {
  if (!path) {
    return false;
  }

  return typeof path === 'string' && path.startsWith('.');
};

/**
 * Map processed external media to `MediaElement`,
 * to be able to use in `@zalora/post-types` cms component
 *
 * NOTE: Modify this function after deep understanding
 * of how it works.
 */
export const normalizeExternalMediaItem = (
  cmsFieldData: RawExternalMedia,
  externalMediaData?: SourceConfigData,
  availability = AVAILABLE,
  isMobile?: boolean,
): MediaWithPlacement => {
  const {
    countdownTimerText: cmsCountdownTimerText = '',
    countdownTimerStartTime: cmsCountdownTimerStart = '',
    countdownTimerEndTime: cmsCountdownTimerEnd = '',
    description: cmsDescription = '',
    height: cmsHeight = 0,
    imageUrl: cmsImageUrl = '',
    internalPromotionalName: cmsInternalPromotionalName = '',
    navLink: cmsNavLink = '',
    title: cmsTitle = '',
    trackingUrl: cmsTrackingURL = '',
    type,
    width: cmsWidth = 0,
    mediaOverlayTitle = '',
    mediaOverlayDescription = '',
    entryId,
  } = cmsFieldData || {};

  const {
    description = '',
    height = 0,
    internalPromotionName: internalPromotionName = '',
    title = '',
    // tracking url in the source config will be the exit url as per RR
    trackingUrl: navLink = '',
    desktopImageUrl: desktopUrl = '',
    url: imageUrl = '',
    width = 0,
    placement = '',
  } = externalMediaData || {};
  const mediaUrl = (!isMobile && desktopUrl ? desktopUrl : imageUrl) || cmsImageUrl;
  const mediaData = {
    mediaUrl: mediaUrl || '',
    width: +cmsWidth || +width,
    height: +cmsHeight || +height,
    mediaTitle: cmsTitle || title,
  };

  return {
    entryId,
    contentType: CONTENT_TYPE.EXTERNAL_MEDIA,
    countdownTimerText: cmsCountdownTimerText,
    countdownTimerStart: toMilliseconds(cmsCountdownTimerStart),
    countdownTimerEnd: toMilliseconds(cmsCountdownTimerEnd),
    internalPromotionalName: cmsInternalPromotionalName || internalPromotionName,
    loadingState: availability === AVAILABLE ? LoadingState.Loaded : LoadingState.Loading,
    mediaUrl,
    navLink: cmsNavLink || navLink,
    title: cmsTitle || title,
    description: cmsDescription || description,
    trackingURL: cmsTrackingURL,
    type: type || MediaType.Image,
    width: +cmsWidth || +width,
    height: +cmsHeight || +height,
    mediaOverlayTitle,
    mediaOverlayDescription,
    placement,
    isFetched: true,
    mediaData,
  };
};

export const normalizeInternalMediaItem = (rawMedia: RawInternalMedia, isMobile?: boolean) => {
  const {
    dWebAbTestName: dWebAbTestName = '',
    mWebAbTestName: mWebAbTestName = '',
    internalPromotionalName: internalPromotionalName = '',
    title = '',
    description = '',
    mediaDesktopWeb: mediaDesktopWeb,
    media: mediaMobile,
    navLink: navLink = '',
    variantNavigationLink: variantNavigationLink = '',
    trackingUrl: trackingURL = '',
    type,
    countdownTimerText: countdownTimerText = '',
    countdownTimerStartTime: countdownTimerStart = '',
    countdownTimerEndTime: countdownTimerEnd = '',
    mediaOverlayTitle = '',
    mediaOverlayDescription = '',
    entryId,
    enableSegmentAudienceFilter = false,
    segmentAudience = '',
    audienceBucket,
    variantMedia: variantMediaMobile,
    variantMediaDesktopWeb: variantMediaDesktop,
  } = rawMedia || {};
  const mediaData = !isMobile && !!mediaDesktopWeb?.file.url ? mediaDesktopWeb : mediaMobile;
  const mediaVariant =
    !isMobile && !!variantMediaDesktop?.file.url ? variantMediaDesktop : variantMediaMobile;

  const abTestName = isMobile ? mWebAbTestName : dWebAbTestName;

  return {
    entryId,
    contentType: CONTENT_TYPE.INTERNAL_MEDIA,
    // countdown timer for media item level is only supported
    // by banner and not by bubble, grid & rec feed
    countdownTimerText,
    countdownTimerStart: toMilliseconds(countdownTimerStart),
    countdownTimerEnd: toMilliseconds(countdownTimerEnd),
    internalPromotionalName,
    title,
    description,
    trackingURL,
    type,
    mediaOverlayTitle,
    mediaOverlayDescription,
    placement: STATIC_PLACEMENT,
    enableSegmentAudienceFilter,
    segmentAudience,
    audienceBucket: audienceBucket || [],
    abTestName,
    navLink,
    variantNavigationLink,
    //store 2 banners for A/B test intention
    mediaData: getInfoInternalMedia(mediaData),
    mediaVariant: getInfoInternalMedia(mediaVariant),
  };
};

export const getInfoInternalMedia = (mediaData?: RawMediaItem) => {
  const mediaTitle = mediaData?.title || '';
  const mediaUrl = mediaData?.file?.url || '';
  const mediaWidth = mediaData?.file?.details?.image?.width || 0;
  const mediaHeight = mediaData?.file?.details?.image?.height || 0;

  return {
    width: +mediaWidth,
    height: +mediaHeight,
    mediaUrl: mediaUrl ? `https:${mediaUrl}` : '',
    mediaTitle,
  };
};

/**
 * checks the visibility of the media based on
 * countdown timer
 */
export const isMediaVisible = ({
  countdownTimerStart,
  countdownTimerEnd,
  mediaUrl,
}: MediaWithPlacement): boolean => {
  if (!mediaUrl) {
    return false;
  }

  if (!countdownTimerStart || !countdownTimerEnd) {
    return true;
  }

  const currentTimeMs = toMilliseconds(Date.now());

  if (currentTimeMs < countdownTimerStart || currentTimeMs > countdownTimerEnd) {
    return false;
  }

  return true;
};

export const getScrollScaleByDevice = ({
  isMobile,
  horizontalScrollScale,
  availableDisplayMedia,
}: {
  isMobile: boolean;
  horizontalScrollScale: HorizontalScale;
  availableDisplayMedia: number;
}): HorizontalScale => {
  if (!horizontalScrollScale || !availableDisplayMedia) {
    return HorizontalScale.NoScale;
  }

  const expectedMediaCount = Math.ceil(parseFloat(horizontalScrollScale));
  const isMediaInsufficient = availableDisplayMedia < expectedMediaCount;
  const isMediaInsufficientForMobile = isMobile && isMediaInsufficient;

  // Handles auto adjust scroll scale for mopbile web. Since mobile has multiple
  // horizontal scale configurations ("1", "1.5" & "2.5"), lotus will fallback to
  // scale setting based on available media.
  if (isMediaInsufficientForMobile) {
    const selectedScrollScaleMobile =
      SCROLL_SCALE_MEDIA_MAPPING_MOBILE?.[availableDisplayMedia as ScrollScaleMappingMobile];

    return selectedScrollScaleMobile || HorizontalScale.NoScale;
  }

  const isMediaInsufficientForDesktop = !isMobile && isMediaInsufficient;

  // Handles auto adjust scroll scale for desktop web. Since desktop has only
  // two possible horizontal scale configurations ("1" & "4.15"), lotus will
  // fallback to scale setting "1" when the condition is met.
  if (isMediaInsufficientForDesktop) {
    return HorizontalScale.NoScale;
  }

  return horizontalScrollScale;
};

export const getIsCustomOffsetBanner = (scrollOffsetByDevice?: HorizontalScale): boolean => {
  if (!scrollOffsetByDevice) {
    return false;
  }

  return CUSTOM_BANNER_OFFSETS.includes(scrollOffsetByDevice);
};

export const getBannerType = (
  media: MediaWithPlacement[],
  autoScroll: boolean | undefined,
  isCustomOffsetBanner: boolean,
) => {
  if (media?.length === 1) {
    return BannerVariations.Fixed;
  }

  return !isCustomOffsetBanner && autoScroll && media.length > 1
    ? BannerVariations.Scrollable // only set bannerType to scrollable when conditions satisfy
    : BannerVariations.Fixed;
};

export const isMediaAvailable = (media: MediaWithPlacement[] | undefined): boolean => {
  if (!media || !Array.isArray(media)) {
    return false;
  }

  return media?.filter((item) => item.mediaUrl)?.length > 0;
};

export const getFormattedPrice = ({
  previousPrice = 0,
  currentPrice = 0,
}: RawProductPrice): ProductPrice => {
  const hasSpecialPrice = !!currentPrice;

  if (!hasSpecialPrice) {
    return {
      current: {
        fullPrice: formatMoney(previousPrice, 'string'),
      },
    };
  }

  return {
    current: {
      fullPrice: formatMoney(currentPrice, 'string'),
    },
    previous: {
      fullPrice: formatMoney(previousPrice, 'string'),
    },
  };
};

export const centToPrice = (cents: string | number, countryIso: string): number => {
  const centsAsNumber = toNumber(cents);

  if (countryIso === 'id') {
    return centsAsNumber;
  }

  return centsAsNumber / 100;
};

export const getDateTimePostType = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const previewTime = searchParams.get('previewTime') || '';
  const isPreviewMode = isTrueValue(searchParams.get('isPreviewMode'));

  if (previewTime && isPreviewMode) {
    return new Date(previewTime).getTime();
  }

  return Date.now();
};

export const removeCountdownTimer = (data: RawMedia) => {
  const { countdownTimerText, countdownTimerStartTime, countdownTimerEndTime, ...media } = data;

  return media;
};

export const displayImageNumber = (banner: MediaWithPlacement[]) => {
  if (banner?.length >= 3 && banner?.length < 6) {
    return 3;
  }

  if (banner?.length >= 6) {
    return 6;
  }

  return 0;
};

export const getAudienceByOrigin = (fetchDataOrigin?: string): string => {
  const isMobile = fetchDataOrigin ? fetchDataOrigin === 'server' : isMobileScreenSize();

  return getAudienceByPlatform(isMobile);
};

// Returns the audience which is used by content types
// other than "segment" within contentful.
export const getAudienceByPlatform = (isMobile: boolean) => {
  if (isMobile) {
    const audienceMweb = [
      AUDIENCE_MOBILE_WEB.AUDIENCE_APP_AND_MWEB,
      AUDIENCE_MOBILE_WEB.AUDIENCE_MWEB,
    ];

    return audienceMweb.join(',');
  }

  return AUDIENCE_DESKTOP.DESKTOP_WEB_ONLY;
};

// Priority (high to low) - ['serverTitle', 'contentfulCmsTitle', 'fallbackTitle']
export const getPageTitle = ({
  serverTitle, // Title fetched from server side (can be either from Contentful or BOB)
  fallbackTitle, // Title from BOB CMS
  contentfulCmsTitle, // Title from Contentful CMS
  contentfulPageFetchStatus,
}: {
  serverTitle: string;
  fallbackTitle: string;
  contentfulCmsTitle: string;
  contentfulPageFetchStatus: string;
}): Nullable<string> => {
  if (contentfulPageFetchStatus === FetchStatus.Loading && serverTitle) {
    return serverTitle;
  }

  if (contentfulPageFetchStatus === FetchStatus.Loaded && contentfulCmsTitle) {
    return contentfulCmsTitle;
  }

  if (contentfulPageFetchStatus === FetchStatus.Loaded && !contentfulCmsTitle && fallbackTitle) {
    return fallbackTitle;
  }

  return null;
};

export const isUsingMobileData = (device?: string) => {
  if (device === 'mobile') {
    return true;
  }

  return false;
};

export const getRatioPost = (mediaList?: MediaEntryContentType[]) => {
  return mediaList?.find((item) => item.ratio)?.ratio;
};

export const ruleDisplayMediaByType = (media: MediaWithPlacement[], post: RawMediaPostType) => {
  const filterMedia = media.filter((item) => item.mediaUrl);

  switch (post.contentType) {
    case CONTENT_TYPE.BANNER: {
      return media?.filter(isMediaVisible);
    }

    case CONTENT_TYPE.BUBBLE: {
      return filterMedia;
    }

    case CONTENT_TYPE.GRID: {
      const { rows = 0, columns = 0 } = (post as RawGrid) || {};

      return isValidGrid(filterMedia, rows, columns) ? filterMedia : [];
    }

    case CONTENT_TYPE.FLEXI_GRID: {
      //category grid
      if (post.postVariant === POST_VARIANT_TYPE.C1C5) {
        return isValidCategoryGrid(filterMedia) && isMediaAvailable(filterMedia) ? filterMedia : [];
      }

      //flexi grid
      return isValidFlexiGrid(filterMedia, (post as RawFlexiGrid).postVariant || '')
        ? filterMedia
        : [];
    }

    default: {
      return [];
    }
  }
};

export const isValidCategoryGrid = (displayMedia: MediaWithPlacement[]) => {
  const totalCategoryGridItems = displayMedia.length;

  // C post type variant must have 5 media
  return totalCategoryGridItems === C_MEDIA_LENGTH;
};

// validate specific grid
export const isValidFlexiGrid = (media: MediaWithPlacement[], postVariant: string) => {
  const totalFlexiGridItems = toNumber(media.length);

  switch (postVariant) {
    case POST_VARIANT_TYPE.CU1CU4: {
      return totalFlexiGridItems >= CU_MEDIA_LENGTH;
    }

    case POST_VARIANT_TYPE.NU1NU2: {
      return totalFlexiGridItems >= NU_MEDIA_LENGTH;
    }

    default: {
      return false;
    }
  }
};

export const isValidGrid = (media: MediaWithPlacement[], rows: number, columns: number) => {
  const totalGridItems = toNumber(rows) * toNumber(columns);

  // grid validation, let's say if rows = 3 and cols = 3
  // then total items in the grid should be 9. If length
  // of variable media is !== 9 then hide entire grid post
  return totalGridItems === media.length;
};

export const isVisibleToPersonalizedUser = (
  userData: { zuid?: ZDTCustomer.Customer['Zuid']; anonymousId?: string },
  contentData: { segmentAudience?: string; audienceBucket?: AudienceBucket[] },
  userTraitAudienceData: ZDTTwilioSegment.ProfilesTraitsAudience['Traits'],
) => {
  const { zuid, anonymousId } = userData;
  const { segmentAudience = '', audienceBucket = [] } = contentData;

  const { isUserAudienceDataExits, userAudienceBucketType } = getUserGroupData(
    userTraitAudienceData,
    zuid,
    anonymousId,
  );

  return (
    // Personalize based on user's segmentAudience if userTraitAudienceData exits.
    (isUserAudienceDataExits && isExistedInAudienceTrait(segmentAudience, userTraitAudienceData)) ||
    // Personalize based on user's audienceBucketData if userTraitAudienceData does not exits.
    (!isUserAudienceDataExits && isExistedInAudienceBucket(audienceBucket, userAudienceBucketType))
  );
};

export const getUserGroupData = (
  userTraitAudienceData: ZDTTwilioSegment.ProfilesTraitsAudience['Traits'],
  zuid?: ZDTCustomer.Customer['Zuid'],
  anonymousId?: string,
): UserGroupData => {
  const isUserAudienceDataExits =
    isObject(userTraitAudienceData) && Object.keys(userTraitAudienceData).length > 0;

  if (anonymousId && !zuid) {
    return { isUserAudienceDataExits, userAudienceBucketType: AudienceBucket.Anonymous };
  }

  if (!anonymousId && zuid && !isUserAudienceDataExits) {
    return { isUserAudienceDataExits, userAudienceBucketType: AudienceBucket.NewlySignedUp };
  }

  return { isUserAudienceDataExits };
};

export const isExistedInAudienceTrait = (
  segmentAudience: string,
  fetchedAudienceSegment?: ZDTTwilioSegment.ProfilesTraitsAudience['Traits'],
) => {
  const audiences = segmentAudience?.replace(/\s/g, '').split('|');

  return audiences.some((audience: string) => {
    if (fetchedAudienceSegment && audience && audience in fetchedAudienceSegment) {
      return (fetchedAudienceSegment as { [key: string]: boolean })[audience] === true;
    }

    return false;
  });
};

export const isExistedInAudienceBucket = (
  audienceBucket: AudienceBucket[],
  userAudienceBucketType?: AudienceBucket,
) => {
  if (!userAudienceBucketType) {
    return false;
  }

  return audienceBucket.includes(userAudienceBucketType);
};
