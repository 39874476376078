export const CONTENTFUL_DELIVERY_HOST = 'cdn.contentful.com';
export const CONTENTFUL_PREVIEW_HOST = 'preview.contentful.com';

export enum AUDIENCE {
  AUDIENCE_WEB = 'web',
  AUDIENCE_APP = 'app',
}

export enum AUDIENCE_MOBILE_WEB {
  AUDIENCE_ALL = 'All',
  AUDIENCE_APP_AND_MWEB = 'App and mWeb',
  AUDIENCE_MWEB = 'mWeb only',
}

const ORDER_BY_UPDATED_AT = '-sys.updatedAt';
const INCLUDE_REFERENCE_LEVEL = 3;

export const DEFAULT_QUERY_PARAMS = {
  order: ORDER_BY_UPDATED_AT,
  include: INCLUDE_REFERENCE_LEVEL,
};

export enum AUDIENCE_DESKTOP {
  ALL = 'All',
  DESKTOP_WEB_ONLY = 'Desktop web only',
}

// -- Content Type IDs --
export enum CONTENT_TYPE {
  SEO = 'seo',
  GRID = 'grid',
  BUBBLE = 'bubble',
  BANNER = 'banner',
  RECOMMENDATION = 'recommendation',
  SEGMENT = 'segment',
  sourceConfig = 'sourceConfig',
  EXTERNAL_MEDIA = 'externalMedia',
  INTERNAL_MEDIA = 'internalMedia',
  TEXT_BANNER = 'textBanner',
  FLEXI_GRID = 'flexiGrid',
  STATIC_PAGE = 'staticPage',
  CATEGORY_GRID = 'categoryGrid',
  HTML = 'html',
  Asset = 'Asset',
  SPONSOR_ADS = 'sponsored-ads',
  SERIAL_RETURNER_BANNER = 'serialReturnerBanner',
}

export enum CONTENT_TYPE_PREVIEW {
  GRID = 'grid',
  BUBBLE = 'bubble',
  BANNER = 'banner',
  RECOMMENDATION = 'recommendation',
  EXTERNAL_MEDIA = 'externalMedia',
  INTERNAL_MEDIA = 'internalMedia',
  TEXT_BANNER = 'textBanner',
  FLEXI_GRID = 'flexiGrid',
  CATEGORY_GRID = 'categoryGrid',
  HTML = 'html',
  SPONSOR_ADS = 'sponsored-ads',
}

// -- Error Messages --
export const CLIENT_NOT_INITIALIZED =
  'The client is not properly initialized. This might have occurred due to a network error or a mismatch param.';
export const CLIENT_PARAMS_NOT_FOUND = 'One of the client params are missing';
