/* eslint-disable max-lines */
import { create as produce, Draft } from 'mutative';
import {
  CONTENT_TYPE,
  AUDIENCE_DESKTOP,
  AUDIENCE_MOBILE_WEB,
  CONTENT_TYPE_PREVIEW,
} from 'constants/contentful';
import {
  SegmentData,
  RawMedia,
  FetchStatus,
  MediaWithPlacement,
  RawExternalMedia,
  RawPostType,
  RawMediaPostType,
  DataSource,
  AudienceBucket,
} from 'types/post-types/PostTypes';
import { RawRecommendation } from 'types/post-types/RecommendationTypes';
import { RawTextBanner } from 'types/post-types/TextBannerTypes';
import { normalizeInternalMediaItem, removeCountdownTimer } from 'utils/post-type/helper';
import { isArrayOfObjects, isEmptyObject, isObject } from 'utils/validation';

export interface ContentfulDataRaw {
  sys: {
    contentType?: {
      sys: {
        id: string;
      };
    };
    type: string;
    id: string;
  };
  fields?: {
    [key: string]: unknown;
    media?: Fields[];
  };
}

export interface Fields {
  sys?: {
    id?: string;
  };
  [key: string]: unknown;
}

export interface ContentfulResponse {
  fields: {
    posts: ContentfulDataRaw[];
    // eslint-disable-next-line camelcase
    data_sources?: { fields?: Fields }[];
    seo?: { fields: Fields };
    backgroundColor?: string;
    postsSpacing?: string;
    title?: string;
    name?: string;
    scheduledActions?: ScheduledActions;
  };
}

interface ContentfulDataUnwrapped {
  media?: RawMedia[];
  entryId?: string;
  contentType?: string;
  fields?: Fields;
  audience?: string;
  media1?: RawMedia;
  media2?: RawMedia;
  media3?: RawMedia;
  media4?: RawMedia;
  media5?: RawMedia;
  isHide?: boolean;
  media1OverlayTitle?: string;
  media1OverlayDescription?: string;
  media2OverlayTitle?: string;
  media2OverlayDescription?: string;
  media3OverlayTitle?: string;
  media3OverlayDescription?: string;
  media4OverlayTitle?: string;
  media4OverlayDescription?: string;
  media5OverlayTitle?: string;
  media5OverlayDescription?: string;
  countdownTimerStart?: string | number | Date | null | undefined;
  countdownTimerEnd?: string | number | Date | null | undefined;
  enableSegmentAudienceFilter?: boolean;
  segmentAudience?: string;
  audienceBucket?: AudienceBucket[];
}

interface ScheduledActions {
  [entryId: string]: ScheduleParam;
}

interface ScheduleParam {
  publishScheduledTime?: string;
  unpublishScheduledTime?: string;
}

const enum STATUS_POST {
  Draft = 'draft',
  Publish = 'publish',
}

export const toNumber = (data: unknown, nanFallback = 0): number => {
  if (!data) {
    return nanFallback;
  }

  const num = Number(data);

  if (Number.isNaN(num)) {
    return nanFallback;
  }

  return num;
};

export const getFields = (data: { fields?: Fields }): Fields => data?.fields || {};

export const processSegmentPageResponse = (
  response?: ContentfulResponse[],
  previewOption?: {
    isPreviewMode?: boolean;
    previewTime?: string;
    shouldDisableMediaTimers?: boolean;
    itemsAvailable: { [s: string]: boolean };
  },
  isMobile?: boolean,
): SegmentData => {
  const {
    posts = [],
    // eslint-disable-next-line camelcase
    data_sources = [],
    backgroundColor,
    postsSpacing = '',
    title = '',
    name = '',
    scheduledActions,
  } = response?.[0].fields || {};

  // eslint-disable-next-line camelcase
  const dataSources = arrayToObject(data_sources.map(processDataSources) as DataSource[], 'id');
  let normalizeMediaList = {};
  let listAbTestFlag = [] as string[];
  let indexShelfRank = 0;
  const { itemsAvailable, isPreviewMode, previewTime, shouldDisableMediaTimers } =
    previewOption || {};

  const unWrapperPosts: RawPostType[] =
    posts &&
    posts.reduce((prev: RawPostType[], currentPost: ContentfulDataRaw) => {
      let formattedPost = currentPost
        ? unWrapper(currentPost, {
            isPreviewMode,
            previewTime,
            scheduledActions,
            itemsAvailable,
          })
        : {};

      const { countdownTimerStart = '', countdownTimerEnd = '' } = formattedPost;

      // this is draft post
      if (formattedPost.isHide) {
        return [...prev];
      }

      if (
        !isVisibleByScreens(formattedPost?.audience, isMobile) ||
        !Object.values(CONTENT_TYPE).includes(formattedPost.contentType as CONTENT_TYPE)
      ) {
        return [...prev];
      }

      // Because these config was defined for PM to support their purpose, hence need to hard code for this case
      if (formattedPost.contentType === CONTENT_TYPE.FLEXI_GRID) {
        formattedPost = {
          ...formattedPost,
          media: [
            {
              ...formattedPost.media1,
              entryId: formattedPost.media1?.entryId,
              mediaOverlayTitle: formattedPost.media1OverlayTitle,
              mediaOverlayDescription: formattedPost.media1OverlayDescription,
            } as RawMedia,
            {
              ...formattedPost.media2,
              entryId: formattedPost.media2?.entryId,
              mediaOverlayTitle: formattedPost.media2OverlayTitle,
              mediaOverlayDescription: formattedPost.media2OverlayDescription,
            } as RawMedia,
            {
              ...formattedPost.media3,
              entryId: formattedPost.media3?.entryId,
              mediaOverlayTitle: formattedPost.media3OverlayTitle,
              mediaOverlayDescription: formattedPost.media3OverlayDescription,
            } as RawMedia,
            {
              ...formattedPost.media4,
              entryId: formattedPost.media4?.entryId,
              mediaOverlayTitle: formattedPost.media4OverlayTitle,
              mediaOverlayDescription: formattedPost.media4OverlayDescription,
            } as RawMedia,

            {
              ...formattedPost.media5,
              entryId: formattedPost.media5?.entryId,
              mediaOverlayTitle: formattedPost.media5OverlayTitle,
              mediaOverlayDescription: formattedPost.media5OverlayDescription,
            } as RawMedia,
          ].filter((item) => item.entryId),
        };
      }

      // text banner or rec feed
      if (!formattedPost?.media) {
        indexShelfRank++;

        return [
          ...prev,
          {
            postShelfRank: indexShelfRank,
            isPersonalizePost: isEnablePersonalizeSegmentData(formattedPost) || false,
            ...formattedPost,
          } as RawTextBanner | RawRecommendation,
        ] as RawPostType[];
      }

      const mediaListAvailable =
        formattedPost.media &&
        formattedPost.media.reduce((mediaCollection, media: RawMedia) => {
          if (!isVisibleByScreens(media.audience, isMobile)) {
            return mediaCollection;
          }

          if (media.contentType === CONTENT_TYPE.EXTERNAL_MEDIA) {
            const dataSourceId = media.sourceConfig?.source ?? '';

            if (!dataSources.hasOwnProperty(dataSourceId)) {
              return mediaCollection;
            }
          }

          if (isPreviewMode && shouldDisableMediaTimers) {
            const mediaWithoutTimers = removeCountdownTimer(media);

            return [...mediaCollection, mediaWithoutTimers];
          }

          return [...mediaCollection, media];
        }, [] as RawMedia[]);

      // Remove post without media
      if (!mediaListAvailable || mediaListAvailable.length === 0) {
        return [...prev];
      }

      listAbTestFlag = getUniqueAbTestNames(mediaListAvailable, listAbTestFlag, isMobile);

      const normalizeMediaOfSinglePost = normalizeMedia(mediaListAvailable, isMobile);

      normalizeMediaList = {
        ...normalizeMediaList,
        ...normalizeMediaOfSinglePost,
      };

      indexShelfRank++;

      return [
        ...prev,
        {
          postShelfRank: indexShelfRank,
          ...formattedPost,
          countdownTimerStart: toMilliseconds(countdownTimerStart) || 0,
          countdownTimerEnd: toMilliseconds(countdownTimerEnd) || 0,
          isPersonalizePost: isEnablePersonalizeSegmentData(formattedPost),
          media: mediaListAvailable.map((item: RawMedia) => {
            const media = isMobile ? item.media : item.mediaDesktopWeb;
            const ratio = getRatioInternalMedia(media?.file?.details?.image);

            return {
              audience: item.audience,
              entryId: item.entryId,
              contentType: item.contentType,
              imageUrl: (isMobile ? media?.file?.url : media?.file?.url) || '',
              ratio,
            };
          }),
        } as RawMediaPostType,
      ];
    }, []);

  return {
    posts: unWrapperPosts,
    mediaList: normalizeMediaList,
    dataSources,
    backgroundColor: backgroundColor || '',
    fetchStatus: FetchStatus.Loaded,
    pageType: CONTENT_TYPE.SEGMENT,
    postsSpacing,
    title,
    name,
    fetchedDataSources: {},
    listAbTestFlag: listAbTestFlag || [],
  };
};

export const processDataSources = (item?: {
  fields?: Fields;
  sys?: { id: string };
}): DataSource | null => {
  if (!item) {
    return null;
  }

  const itemDataSource = { ...item };

  return {
    entryId: itemDataSource.sys?.id || '',
    ...getFields(item),
  } as DataSource;
};

const camelCaseStr = (str: string) => {
  return typeof str !== 'string'
    ? str
    : str
        .split('_')
        .map((s, idx) => (s && idx ? s[0].toUpperCase() + s.slice(1) : s))
        .join('');
};

export const camelCase: {
  <T extends unknown[]>(data: T): T;
  <T extends Record<string, unknown>>(data: T): { [key: string]: (typeof data)[keyof typeof data] };
} = <T>(data: T) => {
  if (Array.isArray(data)) {
    return data.map((value) => camelCase(value));
  }

  if (!isObject(data) || isEmptyObject(data)) {
    return data;
  }

  return Object.keys(data).reduce((results: object, key: string) => {
    const value = data[key];

    return {
      ...results,
      [camelCaseStr(key)]: camelCase(value as Record<string, unknown>),
    };
  }, {});
};

export const isVisibleByScreens = (audience: string | undefined, isMobile?: boolean): boolean => {
  if (isMobile) {
    return isVisibleMWeb(audience);
  }

  return isVisibleDesktop(audience);
};

export const isVisibleDesktop = (audience: string | undefined): boolean =>
  Object.values(AUDIENCE_DESKTOP).includes(audience as AUDIENCE_DESKTOP);

export const isVisibleMWeb = (audience: string | undefined): boolean =>
  Object.values(AUDIENCE_MOBILE_WEB).includes(audience as AUDIENCE_MOBILE_WEB);

export const unWrapper = (
  data: ContentfulDataRaw,
  {
    isPreviewMode,
    scheduledActions,
    previewTime,
    itemsAvailable,
  }: {
    isPreviewMode?: boolean;
    scheduledActions?: ScheduledActions;
    previewTime?: string;
    itemsAvailable?: { [s: string]: boolean };
  },
): ContentfulDataUnwrapped => {
  const fields = produce(getFields(data), (draftFields: Draft<Fields>) => {
    Object.keys(draftFields).forEach((key) => {
      if (key && isArrayOfObjects(draftFields[key])) {
        const arrayContentfulDataRaw = draftFields[
          key
        ] as Array<unknown> as Array<ContentfulDataRaw>;

        draftFields[key] = arrayContentfulDataRaw.map((i: ContentfulDataRaw) =>
          unWrapper(i, { isPreviewMode, scheduledActions, previewTime, itemsAvailable }),
        );
      }

      if (!draftFields[key]) {
        return;
      }

      const fieldKeys = Object.keys(draftFields[key] as object);
      const isPureContentfulObj = fieldKeys.includes('sys') && fieldKeys.includes('fields');

      if (
        key &&
        !Array.isArray(draftFields[key]) &&
        typeof draftFields[key] === 'object' &&
        isPureContentfulObj
      ) {
        draftFields[key] = unWrapper(draftFields[key] as ContentfulDataRaw, {
          isPreviewMode,
          scheduledActions,
          previewTime,
          itemsAvailable,
        });
      }
    });
  });

  const contentTypeName = data?.sys?.contentType?.sys?.id;
  const contentType = contentTypeName || data?.sys?.type;
  const entryId = data?.sys?.id;

  const unWrappedData = { entryId, contentType, ...camelCase(fields) };
  const statusPost = itemsAvailable?.[entryId] ? STATUS_POST.Publish : STATUS_POST.Draft;

  if (
    isPreviewMode &&
    previewTime &&
    Object.values(CONTENT_TYPE_PREVIEW).includes(contentType as CONTENT_TYPE_PREVIEW)
  ) {
    // this is draft post
    if (statusPost === STATUS_POST.Draft && !scheduledActions?.[entryId]) {
      return { ...unWrappedData, isHide: true };
    }

    return shouldVisibleOnScreen(scheduledActions?.[entryId], previewTime, statusPost)
      ? unWrappedData
      : { ...unWrappedData, isHide: true };
  }

  return unWrappedData;
};

export const shouldVisibleOnScreen = (
  scheduledAction?: ScheduleParam,
  previewTime?: string,
  currentStatus?: string,
) => {
  const publishScheduledTime = toMilliseconds(scheduledAction?.publishScheduledTime);
  const unpublishScheduledTime = toMilliseconds(scheduledAction?.unpublishScheduledTime);

  const previewDate = toMilliseconds(previewTime);

  if (publishScheduledTime === unpublishScheduledTime) {
    return currentStatus === STATUS_POST.Publish;
  }

  if (currentStatus === STATUS_POST.Publish) {
    if (!unpublishScheduledTime) {
      return true;
    }

    if (publishScheduledTime < unpublishScheduledTime) {
      return previewDate < unpublishScheduledTime;
    }

    return previewDate < unpublishScheduledTime || publishScheduledTime <= previewDate;
  }

  if (!publishScheduledTime) {
    return false;
  }

  if (unpublishScheduledTime < publishScheduledTime) {
    return publishScheduledTime <= previewDate;
  }

  return publishScheduledTime <= previewDate && previewDate < unpublishScheduledTime;
};

export const arrayToObject = <T>(arr: T[], key: keyof T): { [k: string]: T } =>
  arr.reduce(
    (obj, item) => {
      const keyValue = item[key];

      if (keyValue !== undefined) {
        obj[String(keyValue)] = item;
      }

      return obj;
    },
    {} as { [k: string]: T },
  );

export const normalizeMedia = (array: RawMedia[], isMobile?: boolean) => {
  return array.reduce(
    (obj: { [key: string]: Omit<MediaWithPlacement, 'mediaUrl'> | RawExternalMedia }, item) => {
      // this is draft media
      if (item?.isHide) {
        return obj;
      }

      if (item.contentType === CONTENT_TYPE.EXTERNAL_MEDIA) {
        obj[item.entryId] = item;

        return obj;
      }

      if (item.contentType === CONTENT_TYPE.INTERNAL_MEDIA) {
        const internalItem = normalizeInternalMediaItem(item, isMobile);

        if (!internalItem.mediaData?.mediaUrl && !internalItem.mediaVariant?.mediaUrl) {
          return obj;
        }

        obj[item.entryId] = internalItem;

        return obj;
      }

      return obj;
    },
    {},
  );
};

export const toMilliseconds = (time: string | number | Date | null | undefined): number => {
  if (!time) {
    return 0;
  }

  const mils = new Date(time).getTime();

  return toNumber(mils, 0);
};

export const isTrueValue = (value: unknown) => value === 'true';

export const getRatioInternalMedia = (image?: {
  width: string | number;
  height: string | number;
}) => {
  if (!image) {
    return 0;
  }

  try {
    const width = toNumber(image.width);
    const height = toNumber(image.height);

    return height / (width || 1);
  } catch (error) {
    return 0;
  }
};

const isEnablePersonalizeSegmentData = (post: ContentfulDataUnwrapped) => {
  if (post.enableSegmentAudienceFilter) {
    return true;
  }

  return post.media?.some((item) => item.enableSegmentAudienceFilter);
};

const getUniqueAbTestNames = (
  dataArray: RawMedia[],
  defaultAbTestName: string[],
  isMobile?: boolean,
) =>
  dataArray.reduce((names, item) => {
    if (item.contentType === CONTENT_TYPE.INTERNAL_MEDIA) {
      const abTestName = isMobile ? item.mWebAbTestName : item.dWebAbTestName;

      if (abTestName && !names.includes(abTestName)) {
        names.push(abTestName);
      }
    }

    return names;
  }, defaultAbTestName as string[]);

export const isExternalMedia = (
  media: MediaWithPlacement | RawExternalMedia | undefined,
): media is RawExternalMedia => {
  return isObject(media) && media.contentType === CONTENT_TYPE.EXTERNAL_MEDIA;
};
