import { jsl } from 'json-selector-lang';
import Program from 'json-selector-lang/lib/AST/Program';

const cache: {
  [value: string]: Program;
} = {};

const compile = (path: string) => {
  // checks if cache has the Program (collection of ASTs - Abstract syntax tree)
  // generated for the given path (eg path: '.data.items[0]')
  if (!cache.hasOwnProperty(path)) {
    // compiles the given path and generates AST
    const sel = jsl.compile(path);

    cache[path] = sel;

    return sel;
  }

  // returns the cached AST, I prefer to store it in the file instead
  // of state is because the data is basically collection of
  // classes and will overload the state if stored there.
  return cache[path];
};

export const select = (path: string, data: unknown) => {
  try {
    // all errors generated while compiling and
    // evaluating the path will be caught in ths block
    const sel = compile(path);
    // to evaluate the given path with respect to the data
    // input eg:
    // data = { data: { items: [123,234,345] } }
    // path = '.data.items[0]'
    // output eg: 123
    const val = jsl.evaluate(data, sel);

    return val;
  } catch (e) {}

  return null;
};
