import { getDeviceId } from 'utils/device-id';
import { captureError } from 'utils/raven';

const MAXIMUM_CACHED_USERS = 10;
const NUMBER_OF_LAST_VIEWED_SKUS = 3;

export const LAST_VIEWED_SKUS_KEY = 'lastViewedSkuData';

// The data will be like this
// {
//   recentIds: ['userId1', 'userId2', 'userId3'],
//   viewedSkusMap: {
//        userId1: ['sku1', 'sku2', 'sku3'],
//        userId2: ['sku3', 'sku4', 'sku5'],
//        userId3: ['sku6', 'sku7'],
// }
interface LastViewedSkusData {
  viewedSkusMap?: Record<string, string[]>;
  recentIds?: string[];
}

const writeLastViewedSkusToStorage = (data: LastViewedSkusData) => {
  try {
    localStorage.setItem(LAST_VIEWED_SKUS_KEY, JSON.stringify(data));
  } catch {
    captureError('writeLastViewedSkusToStorage failed', { tag: 'handler' });
  }
};

const getLastViewedSkusFromStorage = () => {
  try {
    const dataAsString = localStorage.getItem(LAST_VIEWED_SKUS_KEY) || '{}';
    const data = (JSON.parse(dataAsString) as LastViewedSkusData) || {};

    return data;
  } catch (error) {
    captureError('getLastViewedSkusFromStorage failed', { error, tag: 'handler' });
  }

  return {};
};

export const addLastViewedSkus = (sku: string) => {
  const user = window.__USER__;
  const userId = (user ? user.Id : getDeviceId()) || '';

  const lastViewedSkusData = getLastViewedSkusFromStorage();
  const viewedSkusMap = lastViewedSkusData.viewedSkusMap || {};
  let recentIds = lastViewedSkusData.recentIds || [];

  viewedSkusMap[userId] = viewedSkusMap[userId]
    ? [...(viewedSkusMap[userId] || []).filter((s) => s !== sku), sku].slice(
        -NUMBER_OF_LAST_VIEWED_SKUS,
      )
    : [sku];

  recentIds = recentIds.filter((id) => id !== userId);
  recentIds.push(userId);

  if (Object.keys(viewedSkusMap).length > MAXIMUM_CACHED_USERS) {
    const oldestUserId = recentIds.shift();

    oldestUserId && delete viewedSkusMap[oldestUserId];
  }

  lastViewedSkusData.recentIds = recentIds;
  lastViewedSkusData.viewedSkusMap = viewedSkusMap;

  writeLastViewedSkusToStorage(lastViewedSkusData);
};

export const getRecentViewedSkus = () => {
  const user = window.__USER__;
  const userId = (user ? user.Id : getDeviceId()) || '';

  const data = getLastViewedSkusFromStorage();

  return data.viewedSkusMap?.[userId] || [];
};
